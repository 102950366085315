import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import PhotoIcon from '@mui/icons-material/Photo';
import { UnitsWithColor } from '../../constants/Colors';
import { useState } from 'react';

export const SubServicesTable = (props: { value: any }) => {
  const isMobileOrTablet = useMediaQuery('(max-width: 1024px)');
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [imageSrc, setImageSrc] = useState('');

  const handleClickOpen = (image: any) => {
    setLoading(true);
    setImageSrc(image);
    setOpen(true);

    const imgElement = new Image();
    imgElement.src = image;

    imgElement.onload = () => {
      setLoading(false);
    };
  };

  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };

  return (
    <Table>
      <TableBody>
        {props.value.map((item: any) => (
          <TableRow
            key={item.id}
            sx={{
              border: 'solid',
              borderWidth: 0.7,
              borderRightColor: '#949494',
            }}
          >
            <TableCell
              align="left"
              style={{
                backgroundColor: '#FEFCFF',
                border: '1px solid',
                width: isMobileOrTablet ? 100 : 500,
                borderColor: '#949494',
                borderRightColor: '#949494',
              }}
            >
              <Stack direction={'column'} spacing={1}>
                <Typography sx={{ fontSize: ['12px', '16px', '18px'] }}>
                  {item.subServices.subServiceName}
                </Typography>
                {item.image && item.image.length !== 0 ? (
                  <div>
                    <Button
                      onClick={() => handleClickOpen(item.image)}
                      variant="outlined"
                      color="primary"
                      size="small"
                      startIcon={<PhotoIcon />}
                      sx={{
                        width: {
                          xs: 120,
                          sm: 140,
                          md: 160,
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          textTransform: 'none',
                          fontSize: {
                            xs: '12px',
                            sm: '14px',
                            md: '15px',
                          },
                        }}
                      >
                        View Image
                      </Typography>
                    </Button>
                  </div>
                ) : null}
              </Stack>
            </TableCell>
            <TableCell
              style={{
                background: UnitsWithColor[item.ratingUnit],
                width: isMobileOrTablet ? 150 : 550,
                border: '1px solid',
                borderColor: '#949494',
              }}
            >
              <Typography
                sx={{ fontSize: ['12px', '16px', '18px'], fontWeight: 600 }}
              >
                {item.ratingUnit}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      {isLoading && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
          onClick={() => {}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {isLoading ? (
            <CircularProgress color="success" />
          ) : (
            <img
              className="not-found"
              src={imageSrc}
              alt="Not Found!"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          )}
        </Box>
      </Modal>
    </Table>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  p: 4,
  width: {
    xs: 300,
    sm: 500,
    md: 600,
  },
  height: {
    xs: 300,
    sm: 500,
    md: 600,
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
