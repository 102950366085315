export const ActionTypes = {
  ADMIN_LOGIN: 'ADMIN_LOGIN',
  ADMIN_LOGOUT: 'ADMIN_LOGOUT',
  FETCH_SERVICE_REQUESTS: 'FETCH_SERVICE_REQUESTS',
  UPDATE_SERVICE_REQUESTS: 'UPDATE_SERVICE_REQUESTS',
  FETCH_INSPECTORS: 'FETCH_INSPECTORS',
  ASSIGNED_SERVICE_REQUESTS: 'ASSIGNED_SERVICE_REQUESTS',
  UPDATE_SERVICE_REQUEST_STATUS: 'UPDATE_SERVICE_REQUEST_STATUS',
  GET_REPORT_DATA: 'GET_REPORT_DATA',
  GET_SERVICE_REQUEST_BY_ID: 'GET_SERVICE_REQUEST_BY_ID',
  GET_SERVICE_REQUEST_BY_UUID: 'GET_SERVICE_REQUEST_BY_UUID',
  GET_ALL_SUBSERVICES: 'GET_ALL_SUBSERVICES',
};
