import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ReactComponent as Customer } from '../../assets/images/customer.svg';
import { ReactComponent as EngineCapacity } from '../../assets/images/capacity.svg';
import { ReactComponent as Mileage } from '../../assets/images/mileage.svg';
import { ReactComponent as FuelType } from '../../assets/images/fuel-type.svg';
import { ReactComponent as Transmission } from '../../assets/images/transmission.svg';
import { ReactComponent as Vin } from '../../assets/images/vin.svg';
import { ReactComponent as EngineNo } from '../../assets/images/engine.svg';
import { ReactComponent as RegistrationNo } from '../../assets/images/reg-no.svg';
import { ReactComponent as Color } from '../../assets/images/color.svg';
import { Stack, ThemeProvider, createTheme } from '@mui/material';
import './styles.css';
import { APP_FONT } from '../../constants/AppFont';

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
});

export const CarDetails = ({ vehicle, consumer }: any) => {
  return (
    <Box>
      <Box sx={{ width: '100%', px: 3, pt: 3 }}>
        <ThemeProvider theme={theme}>
          <Typography sx={ratingLabel}>Car Details</Typography>
          <div className="details-container">
            <div className="details-row">
              <Customer width={20} height={20} style={{ marginRight: 8 }} />
              <Stack direction={'column'}>
                <Typography sx={{ color: 'grey' }}>
                  Customer/Dealer Name
                </Typography>
                <Typography sx={{ color: '#000', fontWeight: 500 }}>
                  {consumer?.fullName}
                </Typography>
              </Stack>
            </div>
            <div className="details-row">
              <EngineCapacity
                width={20}
                height={20}
                style={{ marginRight: 8 }}
              />
              <Stack direction={'column'}>
                <Typography sx={{ color: 'grey' }}>Engine Capacity</Typography>
                <Typography sx={{ color: '#000', fontWeight: 500 }}>
                  {vehicle?.engineCapacity}
                </Typography>
              </Stack>
            </div>
            <div className="details-row">
              <Mileage width={20} height={20} style={{ marginRight: 8 }} />
              <Stack direction={'column'}>
                <Typography sx={{ color: 'grey' }}>Mileage</Typography>
                <Typography sx={{ color: '#000', fontWeight: 500 }}>
                  {vehicle?.mileage} km
                </Typography>
              </Stack>
            </div>
            <div className="details-row">
              <FuelType width={20} height={20} style={{ marginRight: 8 }} />
              <Stack direction={'column'}>
                <Typography sx={{ color: 'grey' }}>Fuel Type</Typography>
                <Typography sx={{ color: '#000', fontWeight: 500 }}>
                  {vehicle?.fuelType}
                </Typography>
              </Stack>
            </div>
            <div className="details-row">
              <Transmission width={20} height={20} style={{ marginRight: 8 }} />
              <Stack direction={'column'}>
                <Typography sx={{ color: 'grey' }}>
                  Transmission Type
                </Typography>
                <Typography sx={{ color: '#000', fontWeight: 500 }}>
                  {vehicle?.transmissionType}
                </Typography>
              </Stack>
            </div>
            <div className="details-row">
              <Vin width={20} height={20} style={{ marginRight: 8 }} />
              <Stack direction={'column'}>
                <Typography sx={{ color: 'grey' }}>VIN</Typography>
                <Typography sx={{ color: '#000', fontWeight: 500 }}>
                  {vehicle?.vin}
                </Typography>
              </Stack>
            </div>
            <div className="details-row">
              <EngineNo width={20} height={20} style={{ marginRight: 8 }} />
              <Stack direction={'column'}>
                <Typography sx={{ color: 'grey' }}>Engine No</Typography>
                <Typography sx={{ color: '#000', fontWeight: 500 }}>
                  {vehicle?.engineNo}
                </Typography>
              </Stack>
            </div>
            <div className="details-row">
              <RegistrationNo
                width={20}
                height={20}
                style={{ marginRight: 8 }}
              />
              <Stack direction={'column'}>
                <Typography sx={{ color: 'grey' }}>Registration No</Typography>
                <Typography sx={{ color: '#000', fontWeight: 500 }}>
                  {vehicle?.registrationNo}
                </Typography>
              </Stack>
            </div>
            <div className="details-row">
              <Color width={20} height={20} style={{ marginRight: 8 }} />
              <Stack direction={'column'}>
                <Typography sx={{ color: 'grey' }}>Color</Typography>
                <Typography sx={{ color: '#000', fontWeight: 500 }}>
                  {vehicle?.color}
                </Typography>
              </Stack>
            </div>
          </div>
        </ThemeProvider>
      </Box>
    </Box>
  );
};

const ratingLabel = {
  fontSize: ['20px', '22px', '24px'],
  color: '#000',
  fontWeight: 600,
  marginBottom: 2,
};
