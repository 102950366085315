import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Divider,
  Paper,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
  createTheme,
  useMediaQuery,
} from '@mui/material';
import { APP_FONT } from '../../constants/AppFont';
import LinearProgressWithLabel from '../../components/inspectionReport/progressBar';
import { useEffect, useState } from 'react';
import { ReactComponent as EfyxLogoReport } from '../../assets/images/efyxLogoReport.svg';
import { CarDetails } from '../../components/inspectionReport/carDetails';
import RatingCoreServices from '../../components/inspectionReport/ratingCoreServices';
import { useParams } from 'react-router-dom';
import {
  getAllSubServices,
  getReportData,
  getServiceRequestById,
  getServiceRequestByUuid,
} from '../../redux/actions/service_request';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Vehicle } from '../../models/vehicle';
import { InspectionDetails } from '../../models/inspection_report';
import './styles.css';
import { Consumer } from '../../models/consumer';
import Pie from '../../utils/circularProgressBar';

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
});

export const GenerateReport = () => {
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery('(max-width: 1024px)');
  const carIconSize = isMobileOrTablet ? 100 : 250;
  const boxHeight = isMobileOrTablet ? 110 : 300;
  const boxWidth = isMobileOrTablet ? 150 : 400;
  const px = isMobileOrTablet ? 3 : 10;
  const py = isMobileOrTablet ? 2 : 5;
  const { serviceRequestId } = useParams();

  const [report, setReport] = useState<InspectionDetails[]>([]);
  const [allSubServices, setAllSubServices] = useState<any>([]);
  const [vehicle, setVehicle] = useState<Vehicle>();
  const [consumer, setConsumer] = useState<Consumer>();

  const getInspectionRatings = () => {
    if (report.length > 0) {      
      const inspection = report.filter((a: InspectionDetails) => {
        return a.subServices === null && a.coreServices === null ;
      });    
      const ratingPercentage = (inspection[0]?.ratingPercentage || 0) / 10;
      return Number.isInteger(ratingPercentage) ? ratingPercentage.toFixed(0) : ratingPercentage.toFixed(1);
    }
    return 0;
  };

  const CarIcon = ({ vehicleImage }: any) => {
    return (
      <img
        src={vehicleImage}
        alt="Car Icon"
        style={{ width: '100%', height: '100%', objectFit: 'cover', textAlign: 'center', justifyContent: 'center' }}
      />
    );
  };

  useEffect(() => {
    if (serviceRequestId) {
      dispatch<any>(getReportData(serviceRequestId))
        .then(unwrapResult)
        .then((report: any) => {
          console.log(report, '======report=====');
          setReport(report);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
    if (serviceRequestId) {
      dispatch<any>(getAllSubServices())
        .then(unwrapResult)
        .then((subServices: any) => {
          setAllSubServices(subServices);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
    if (serviceRequestId) {
      dispatch<any>(getServiceRequestByUuid(serviceRequestId))
        .then(unwrapResult)
        .then((serviceRequest: any) => {
          setVehicle(serviceRequest.vehicle);
          setConsumer(serviceRequest.consumer);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }, [serviceRequestId]);

  const mainView = {
    flexGrow: 1,
    py: 1,
    px: isMobileOrTablet ? 1.5 : 3,
  };

  return (
    <Box className="box-view">
      <Paper elevation={0} sx={{ backgroundColor: '#e0f1f1' }}>
        <CssBaseline />
        <Toolbar />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          padding="10px"
        >
          <EfyxLogoReport className="efyx-logo" />
          <Typography sx={heading}>Car Inspection Report</Typography>
        </Box>
        <Divider />
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          px={px}
          py={py}
        >
          <Box
            sx={{
              // backgroundColor: '#0EA1AB',
              height: boxHeight,
              width: boxWidth,
              alignSelf: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <CarIcon
              width={carIconSize}
              height={carIconSize}
              vehicleImage={vehicle?.image}
            />
          </Box>
          <Stack width={'40%'} direction={'column'} alignItems={'center'} justifyContent={'center'} textAlign={'center'}>
            {vehicle && (
              <Typography
                sx={carName}
              >{`${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`}</Typography>
            )}
            <Pie percentage={getInspectionRatings()} colour="#0EA1AB" />
          </Stack>
        </Stack>

        {/* <Box sx={{ backgroundColor: '#0EA1AB', paddingY: 2 }}>
          <Stack
            direction={'row'}
            sx={{ justifyContent: 'space-between', marginX: 3 }}
          >
            <Typography sx={ratingLabel}>Overall Rating</Typography>
            <Typography
              sx={ratingLabel}
            >{`${getInspectionRatings()}/10`}</Typography>
          </Stack>
        </Box> */}
        <Box>
          <CarDetails vehicle={vehicle} consumer={consumer} />
        </Box>
        <Box sx={mainView}>
          <ThemeProvider theme={theme}>
            {isMobileOrTablet && (
              <Box
                sx={{
                  border: '1px solid #ccc',
                  padding: '10px',
                }}
              >
                {report
                  .filter((report) => {
                    return (
                      report.subServices === null &&
                      report.coreServices !== null
                    );
                  })
                  .map((i) => (
                    <LinearProgressWithLabel
                      value={i.ratingPercentage}
                      name={i.coreServices.coreServiceName}
                    />
                  ))}
              </Box>
            )}
            {!isMobileOrTablet &&
              report
                .filter(
                  (report) =>
                    report.subServices === null && report.coreServices !== null,
                )
                .map((i) => (
                  <LinearProgressWithLabel
                    value={i.ratingPercentage}
                    name={i.coreServices.coreServiceName}
                  />
                ))}
          </ThemeProvider>
        </Box>
        <Divider style={{ marginTop: isMobileOrTablet ? 12 : 3 }} />

        <Box sx={ratingCoreServicesContainer}>
          <ThemeProvider theme={theme}>
            {report
              .filter(
                (report) =>
                  report.subServices === null && report.coreServices !== null,
              )
              .map((i) => (
                <RatingCoreServices
                  coreServiceName={i.coreServices.coreServiceName}
                  ratingPercentage={Number(i.ratingPercentage).toFixed(0)}
                  subServicesWithRating={report.filter((subService: any) => {
                    return (
                      subService?.coreServices?.id === i?.coreServices.id &&
                      subService?.subServices !== null
                    );
                  })}
                  subServicesWithOutRating={allSubServices.filter(
                    (subService: any) => {
                      return subService?.coreServices?.id === i.coreServices.id;
                    },
                  )}
                />
              ))}
          </ThemeProvider>
        </Box>
      </Paper>
    </Box>
  );
};

const heading = {
  fontFamily: APP_FONT,
  fontWeight: 500,
  fontSize: ['24px', '30px', '36px'],
  color: '#003650',
};

const carName = {
  marginTop: 1,
  marginBottom: 2,
  fontFamily: APP_FONT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 600,
  fontSize: ['14px', '20px', '28px'],
  color: '#003650',
};

const ratingLabel = {
  fontSize: ['16px', '18px', '20px'],
  color: '#fff',
  fontFamily: APP_FONT,
  fontWeight: 500,
};

const ratingCoreServicesContainer = {
  marginTop: 3,
};
