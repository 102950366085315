import { useMediaQuery } from '@mui/material';
import { APP_FONT } from '../constants/AppFont';

const cleanPercentage = (percentage) => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0;
  const tooHigh = percentage > 100;
  return tooLow ? 0 : tooHigh ? 100 : +percentage;
};

const Circle = ({ colour, pct }) => {
  const isMobileOrTablet = useMediaQuery('(max-width: 1024px)');
  const r = isMobileOrTablet ? 55 : 120;
  const circ = 2 * Math.PI * r;
  const strokePct = ((10 - pct) * circ) / 10;
  return (
    <circle
      r={r}
      cx={0}
      cy={0}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ''}
      strokeWidth={'0.5rem'}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

const Text = ({ percentage }) => {
  const isMobileOrTablet = useMediaQuery('(max-width: 1024px)');
  const text = isMobileOrTablet ? '1em' : '2.2em';
  const formattedPercentage = Number.isInteger(percentage) ? percentage.toString() : percentage.toFixed(1);
  return (
    <g>
      <text
        x="50%"
        y="40%"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize={isMobileOrTablet ? '0.55em' : '1.1em'}
        fontWeight={500}
        fontFamily={APP_FONT}
        fill="#003650"
      >
        Overall Rating
      </text>
      <text
        x="50%"
        y="55%"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize={text}
        fontWeight={500}
        fontFamily={APP_FONT}
        fill="#B70000"
      >
        {formattedPercentage}/10
      </text>
    </g>
  );
};

const Pie = ({ percentage, colour }) => {
  const pct = cleanPercentage(percentage);
  const isMobileOrTablet = useMediaQuery('(max-width: 1024px)');
  const svgSize = isMobileOrTablet ? 120 : 250;
  return (
    <svg width={svgSize} height={svgSize} style={{ overflow: 'visible' }}>
      <g transform={`translate(${svgSize / 2}, ${svgSize / 2}) rotate(-90)`}>
        <Circle colour="lightgray" />
        <Circle colour={colour} pct={pct} />
      </g>
      <Text percentage={pct} />
    </svg>
  );
};

export default Pie;
