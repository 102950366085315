export enum Colors {
    RED = '#FFE0E0',
    GREEN = '#EDFDF1',
    ORANGE = '#FFE3C8',
    YELLOW = '#FEFED5',
  }

  export type ColorsType = {
    [key: string]: string;
  };

  export const UnitsWithColor: ColorsType = {
    "Accidented": Colors.RED,
    "Non Accidented": Colors.GREEN,
    "Complete And Clean": Colors.GREEN,
    "Incomplete And Not Clean": Colors.RED,
    "Complete But Not Clean": Colors.ORANGE,
    "Incomplete But Clean": Colors.ORANGE,
    "Leakage": Colors.RED,
    "No Leakage": Colors.GREEN,
    "Ok": Colors.GREEN,
    "Not Ok": Colors.RED,
    "Normal": Colors.GREEN,
    "Damage": Colors.RED,
    "Repaired": Colors.ORANGE,
    "Present": Colors.GREEN,
    "Not Present": Colors.RED,
    "Noise": Colors.RED,
    "No Noise": Colors.GREEN,
    "Vibration": Colors.RED,
    "No Vibration": Colors.GREEN,
    "Working": Colors.GREEN,
    "Not Working": Colors.RED,
    "Smooth": Colors.GREEN,
    "Rough": Colors.RED,
    "Play": Colors.RED,
    "Boot Damage": Colors.RED,
    "No Damage Found": Colors.GREEN,
    "Damaged": Colors.RED,
    "Polished": Colors.GREEN,
    "Scratched": Colors.RED,
    "Blurred": Colors.ORANGE,
    "Showing Reflection": Colors.RED,
    "Working Properly": Colors.GREEN,
    "Working With Issues": Colors.ORANGE,
    "Perfect": Colors.GREEN,
    "Dirty": Colors.RED,
    "Not Checked Due To Market Cover": Colors.ORANGE,
    "Complete": Colors.GREEN,
    "Incomplete": Colors.RED,
    "Yes": Colors.GREEN,
    "No": Colors.RED,
    "Excellent Air Throw": Colors.GREEN,
    "Bad Air Throw": Colors.RED,
    "Excellent": Colors.GREEN,
    "Not Good": Colors.RED,
    "Error": Colors.RED,
    "No Error": Colors.GREEN,
    "Low": Colors.RED,
    "Full": Colors.GREEN,
    "Scratches": Colors.RED,
    "Cracked Or Broken": Colors.RED,
    "Cleaning Properly": Colors.GREEN,
    "Not Cleaning Properly": Colors.RED,
    "Broken": Colors.RED,
    "No Damage": Colors.GREEN,
    "Clear": Colors.GREEN,
    "Not Applicable": Colors.YELLOW
  }