import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Button,
  CircularProgress,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InspectionDetails } from '../../models/inspection_report';
import { SubServicesTable } from './subServicesTable';
import PhotoIcon from '@mui/icons-material/Photo';
import { UnitsWithColor } from '../../constants/Colors';
import { useState } from 'react';

type MyObjectType = {
  [key: string]: InspectionDetails[];
};

export default function RatingCoreServices(props: {
  ratingPercentage: string;
  coreServiceName: string;
  subServicesWithOutRating: {
    coreServices: any;
    created_at: any;
    updated_at: any;
    id: number;
    subServiceName: string;
    parentId?: number;
  }[];
  subServicesWithRating: InspectionDetails[];
}) {
  const isMobileOrTablet = useMediaQuery('(max-width: 1024px)');
  const parentIdWithChilds: MyObjectType = {};
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [imageSrc, setImageSrc] = useState('');

  const handleClickOpen = (image: any) => {
    setLoading(true);
    setImageSrc(image);
    setOpen(true);

    const imgElement = new Image();
    imgElement.src = image;

    imgElement.onload = () => {
      setLoading(false);
    };
  };

  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };

  for (const item of props.subServicesWithRating) {
    const parentId = item.subServices.parentId;

    let parentName: string = '';
    for (const item of props.subServicesWithOutRating) {
      if (item.id === parentId) {
        parentName = item.subServiceName;
      }
    }

    if (parentId) {
      if (!parentIdWithChilds[parentName]) {
        parentIdWithChilds[parentName] = [];
      }
      parentIdWithChilds[parentName].push(item);
    }
  }

  console.log(parentIdWithChilds, '===== parentIdWithChilds ========');

  return (
    <Accordion style={{ marginBottom: 20 }}>
      <Box sx={{ backgroundColor: '#0EA1AB' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box sx={{ paddingY: isMobileOrTablet ? 'none' : 1, width: '100%' }}>
            <Stack
              direction={'row'}
              sx={{
                justifyContent: 'space-between',
                marginX: isMobileOrTablet ? 0.5 : 2,
              }}
            >
              <Typography sx={ratingLabel}>{props.coreServiceName}</Typography>
              <Typography sx={ratingLabel}>
                {props.ratingPercentage + '%'}
              </Typography>
            </Stack>
          </Box>
        </AccordionSummary>
      </Box>

      <AccordionDetails sx={{ backgroundColor: '#e0f1f1' }}>
        {!Object.keys(parentIdWithChilds).length ? (
          <SubServicesTable value={props.subServicesWithRating} />
        ) : (
          <>
            {Object.keys(parentIdWithChilds).map((key) => (
              <Box>
                <Typography
                  sx={{
                    textAlign: 'start',
                    fontSize: ['18px', '20px', '22px'],
                    fontWeight: 600,
                    marginY: 2,
                  }}
                  variant="body2"
                >
                  {key}
                </Typography>
                {
                  <Table>
                    <TableBody>
                      {parentIdWithChilds[key].map((item) => (
                        <TableRow
                          key={item.id}
                          sx={{
                            border: 'solid',
                            borderWidth: 0.7,
                            borderRightColor: '#949494',
                          }}
                        >
                          <TableCell
                            align="left"
                            style={{
                              backgroundColor: '#FEFCFF',
                              border: '1px solid',
                              width: isMobileOrTablet ? 100 : 500,
                              borderColor: '#949494',
                              borderRightColor: '#949494',
                            }}
                          >
                            <Stack direction={'column'} spacing={1}>
                              <Typography
                                sx={{ fontSize: ['12px', '16px', '18px'] }}
                              >
                                {item.subServices.subServiceName}
                              </Typography>
                              {item.image && item.image.length !== 0 ?
                              <div>
                                <Button
                                  onClick={() => handleClickOpen(item.image)}
                                  variant="outlined"
                                  color="primary"
                                  size="small"
                                  startIcon={<PhotoIcon />}
                                  sx={{
                                    width: {
                                      xs: 120,
                                      sm: 140,
                                      md: 160,
                                    },
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      textTransform: 'none',
                                      fontSize: {
                                        xs: '12px',
                                        sm: '14px',
                                        md: '15px',
                                      },
                                    }}
                                  >
                                    View Image
                                  </Typography>
                                </Button>
                              </div>
                               : null }
                            </Stack>
                          </TableCell>
                          <TableCell
                            style={{
                              background: UnitsWithColor[item.ratingUnit],
                              width: isMobileOrTablet ? 150 : 550,
                              border: '1px solid',
                              borderColor: '#949494',
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: ['12px', '16px', '18px'],
                                fontWeight: 600,
                              }}
                            >
                              {item.ratingUnit}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    {isLoading && (
                      <Backdrop
                        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                        onClick={() => {}}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    )}
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        {isLoading ? (
                          <CircularProgress color="success" />
                        ) : (
                          <img
                            className="not-found"
                            src={imageSrc}
                            alt="Not Found!"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        )}
                      </Box>
                    </Modal>
                  </Table>
                }
              </Box>
            ))}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

const ratingLabel = {
  fontSize: ['16px', '18px', '20px'],
  color: '#fff',
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  p: 4,
  width: {
    xs: 300,
    sm: 500,
    md: 600,
  },
  height: {
    xs: 300,
    sm: 500,
    md: 600,
  },
  display: 'flex',
  justifyContent: 'center', // Horizontally center the content
  alignItems: 'center', // Vertically center the content
};
